import React, { useState, useRef } from "react";
import { Link, graphql } from "gatsby";
import Swiper from "react-id-swiper";
// import "swiper/css/swiper.css";
import Header from "../components/header";
import "../styles/styles.css";
var slugify = require("../helpers");

const Voorstellingen = ({ data, pageContext }) => {
  const [swiper, setSwiper] = useState(null);
  const ref = useRef(null);

  //console.log(data.allVoorstellingenResults.edges)
  const Slides = data.allMysqlVoorstellingen.edges.map((voorstelling) => (
    <div key={voorstelling.node.id}>
      <Link to={"/voorstelling/" + slugify(voorstelling.node.title_nl)}>
        <img
          src={
            "https://www.circusronaldo.be/uploads/" +
            voorstelling.node.mobile_image
          }
          alt=""
        />
      </Link>
    </div>
  ));
  const params = {
    // pagination: {
    //   el: ".swiper-pagination",
    //   type: "bullets",
    //   clickable: true,
    // },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };
  return (
    <div>
      <Header back="true" taal={pageContext.langKey} />
      <div>
        <h1>Voorstellingen</h1>
        <Swiper ref={ref}>{Slides}</Swiper>
        <div
          className="swiper-button-prev"
          onClick={() => {
            if (ref.current !== null && ref.current.swiper !== null) {
              ref.current.swiper.slidePrev();
            }
          }}></div>
        <div
          className="swiper-button-next"
          onClick={() => {
            if (ref.current !== null && ref.current.swiper !== null) {
              ref.current.swiper.slideNext();
            }
          }}></div>
      </div>
    </div>
  );
};
export default Voorstellingen;
export const query = graphql`
  query {
    allMysqlVoorstellingen {
      edges {
        node {
          id
          title_nl
          mobile_image
        }
      }
    }
  }
`;
